module.exports = {
  fi: {
    '0': {
      a: 'Yleistä',
      b: 'Web-sovelluksen toimintaperiaatteita',
    },
    '1': {
      a: 'Reactin alkeet',
      b: 'JavaScriptia',
      c: 'Komponentin tila ja tapahtumankäsittely',
      d: 'Monimutkaisempi tila, Reactin debuggaus',
    },
    '2': {
      a: 'Kokoelmien renderöinti ja moduulit',
      b: 'Lomakkeiden käsittely',
      c: 'Palvelimella olevan datan hakeminen',
      d: 'Palvelimella olevan datan muokkaaminen',
      e: 'Tyylien lisääminen React-sovellukseen',
    },
    '3': {
      a: 'Node.js ja Express',
      b: 'Sovellus internetiin',
      c: 'Tietojen tallettaminen MongoDB-tietokantaan',
      d: 'Validointi ja ESLint',
    },
    '4': {
      a: 'Sovelluksen rakenne ja testauksen alkeet',
      b: 'Backendin testaaminen',
      c: 'Käyttäjien hallinta',
      d: 'Token-perustainen kirjautuminen',
      e: 'Legacy: testaaminen Jestiä käyttäen',
    },
    '5': {
      a: 'Kirjautuminen frontendissä',
      b: 'props.children ja proptypet',
      c: 'React-sovellusten testaaminen',
      d: 'End to end -testaus: Playwright',
      e: 'End to end -testaus: Cypress',
    },
    '6': {
      a: 'Flux-arkkitehtuuri ja Redux',
      b: 'Monta reduseria',
      c: 'Redux-sovelluksen kommunikointi palvelimen kanssa',
      d: 'React Query, useReducer ja context',
    },
    '7': {
      a: 'React Router',
      b: 'custom-hookit',
      c: 'Lisää tyyleistä',
      d: 'Webpack',
      e: 'Luokkakomponentit, Sekalaista',
      f: 'Tehtäviä: blogilistan laajennus',
    },
    '8': {
      a: 'GraphQL-palvelin',
      b: 'React ja GraphQL',
      c: 'Tietokanta ja käyttäjien hallinta',
      d: 'Kirjautuminen ja välimuistin päivitys',
      e: 'Fragmentit ja subskriptiot',
    },
    '9': {},
    '10': {},
    '11': {},
    '12': {},
    '13': {
      a: 'Relaatiotietokannan käyttö Sequelize-kirjastolla',
      b: 'Liitostaulut ja -kyselyt',
      c: 'Migraatiot, monen suhde moneen -yhteydet',
    },
  },
  en: {
    '0': {
      a: 'General info',
      b: 'Fundamentals of Web apps',
    },
    '1': {
      a: 'Introduction to React',
      b: 'JavaScript',
      c: 'Component state, event handlers',
      d: 'A more complex state, debugging React apps',
    },
    '2': {
      a: 'Rendering a collection, modules',
      b: 'Forms',
      c: 'Getting data from server',
      d: 'Altering data in server',
      e: 'Adding styles to React app',
    },
    '3': {
      a: 'Node.js and Express',
      b: 'Deploying app to internet',
      c: 'Saving data to MongoDB',
      d: 'Validation and ESLint',
    },
    '4': {
      a: 'Structure of backend application, introduction to testing',
      b: 'Testing the backend',
      c: 'User administration',
      d: 'Token authentication',
      e: 'Legacy: Testing with Jest',
    },
    '5': {
      a: 'Login in frontend',
      b: 'props.children and proptypes',
      c: 'Testing React apps',
      d: 'End to end testing: Playwright',
      e: 'End to end testing: Cypress',
    },
    '6': {
      a: 'Flux-architecture and Redux',
      b: 'Many reducers',
      c: 'Communicating with server in a Redux application',
      d: 'React Query, useReducer and the context',
    },
    '7': {
      a: 'React Router',
      b: 'Custom hooks',
      c: 'More about styles',
      d: 'Webpack',
      e: 'Class components, Miscellaneous',
      f: 'Exercises: extending the bloglist',
    },
    '8': {
      a: 'GraphQL-server',
      b: 'React and GraphQL',
      c: 'Database and user administration',
      d: 'Login and updating the cache',
      e: 'Fragments and subscriptions',
    },
    '9': {
      a: 'Background and introduction',
      b: 'First steps with TypeScript',
      c: 'Typing an Express app',
      d: 'React with types',
      e: 'Grande finale: Patientor',
    },
    '10': {
      a: 'Introduction to React Native',
      b: 'React Native basics',
      c: 'Communicating with server',
      d: 'Testing and extending our application',
    },
    '11': {
      a: 'Introduction to CI/CD',
      b: 'Getting started with GitHub Actions',
      c: 'Deployment',
      d: 'Keeping green',
      e: 'Expanding Further',
    },
    '12': {
      a: 'Introduction to Containers',
      b: 'Building and configuring environments',
      c: 'Basics of Orchestration',
    },
    '13': {
      a: 'Using relational databases with Sequelize',
      b: 'Join tables and queries',
      c: 'Migrations, many-to-many relationships',
    },
  },
  zh: {
    '0': {
      a: '基础知识',
      b: 'Web 应用的基础设施',
    },
    '1': {
      a: 'React 简介',
      b: 'JavaScript',
      c: '组件状态，事件处理',
      d: '深入React 应用调试',
    },
    '2': {
      a: '从渲染集合到模块学习',
      b: '表单',
      c: '从服务器获取数据',
      d: '在服务端将数据Alert出来',
      e: '给React应用加点样式',
    },
    '3': {
      a: 'Node.js 与 Express',
      b: '把应用部署到网上',
      c: '将数据存入MongoDB',
      d: 'ESLint与代码检查',
    },
    '4': {
      a: '从后端结构到测试入门',
      b: '测试后端应用',
      c: '用户管理',
      d: '密钥认证',
    },
    '5': {
      a: '完成前端的登录功能',
      b: 'props.children 与 proptypes',
      c: '测试React 应用',
      d: '端到端测试',
    },
    '6': {
      a: 'Flux架构与Redux',
      b: '再来点 reducers',
      c: '在Redux应用中与后端通信',
      d: 'connect方法',
    },
    '7': {
      a: 'React-router',
      b: '自定义 hooks',
      c: '样式进阶',
      d: 'Webpack',
      e: '各种各样的Class components',
      f: '练习：扩展你的博客列表',
    },
    '8': {
      a: 'GraphQL服务器',
      b: 'React 与 GraphQL',
      c: '数据库与用户管理',
      d: '登录与更新缓存',
      e: 'Fragments 与 subscriptions',
    },
    '9': {
      a: '背景与介绍',
      b: 'TypeScript的一小步',
      c: 'TypeScript版的express应用',
      d: '利用TypeScript编写React应用',
    },
    '10': {
      a: 'React Native 介绍',
      b: 'React Native 入门',
      c: '与服务端通信',
      d: '测试与扩展我们的应用',
    },
    '11': {
      a: 'CI/CD 简介',
      b: '开始认识 GitHub Actions 吧',
      c: '部署',
      d: '保持健康状态',
      e: '再扩展一下',
    },
    '12': {
      a: '容器介绍',
      b: '构建配置环境',
      c: '编排基础',
    },
    '13': {
      a: '用 Sequelize 使用关系型数据库',
      b: '加入表与查询',
      c: '迁移，多对多关系',
    },
  },
  es: {
    '0': {
      a: 'Información general',
      b: 'Fundamentos de las aplicaciones web',
    },
    '1': {
      a: 'Introducción a React',
      b: 'JavaScript',
      c: 'Estado del componente, controladores de eventos',
      d: 'Un estado más complejo, depurando aplicaciones React',
    },
    '2': {
      a: 'Renderizando una colección, módulos',
      b: 'Formularios',
      c: 'Obteniendo datos del servidor',
      d: 'Alterando datos en el servidor',
      e: 'Agregar estilos a la aplicación React',
    },
    '3': {
      a: 'Node.js y Express',
      b: 'Despliegue de la aplicación a Internet',
      c: 'Guardando datos en MongoDB',
      d: 'Validación y ESLint',
    },
    '4': {
      a: 'Estructura de la aplicación backend, introducción a las pruebas',
      b: 'Probando el backend',
      c: 'Administración de usuarios',
      d: 'Autenticación basada en token',
      e: 'Legacy: Testing con Jest',
    },
    '5': {
      a: 'Iniciar sesión en la interfaz',
      b: 'props.children y proptypes',
      c: 'Probando aplicaciones React',
      d: 'Pruebas de extremo a extremo: Playwright',
      e: 'Pruebas de extremo a extremo: Cypress',
    },
    '6': {
      a: 'Flux-architecture y Redux',
      b: 'Muchos reducers',
      c: 'Comunicarse con el servidor en una aplicación redux',
      d: 'React Query, useReducer y  el contexto',
    },
    '7': {
      a: 'React-router',
      b: 'Hooks personalizados',
      c: 'Más sobre estilos',
      d: 'Webpack',
      e: 'Componentes de clase, misceláneos',
      f: 'Ejercicios: ampliar la lista de blogs',
    },
    '8': {
      a: 'Servidor GraphQL',
      b: 'React y GraphQL',
      c: 'Administración de bases de datos y usuarios',
      d: 'Iniciar sesión y actualizar la caché',
      e: 'Fragmentos y suscripciones',
    },
    '9': {
      a: 'Antecedentes e introducción',
      b: 'Primeros pasos con TypeScript',
      c: 'Tipando una aplicación Express',
      d: 'React con tipos',
      e: 'Grande finale: Patientor',
    },
    '10': {
      a: 'Introducción a React Native',
      b: 'Conceptos básicos de React Native',
      c: 'Comunicándose con el servidor',
      d: 'Probar y ampliar nuestra aplicación',
    },
    '11': {
      a: 'Introducción a CI/CD',
      b: 'Introducción a las acciones de Github',
      c: 'Despliegue',
      d: 'Manteniéndose verde',
      e: 'Expandiéndose aún más',
    },
    '12': {
      a: 'Introducción a los Contenedores',
      b: 'Construir y configurar entornos',
      c: 'Conceptos básicos de orquestación',
    },
    '13': {
      a: 'Uso de bases de datos relacionales con Sequelize',
      b: 'Unir tablas y consultas',
      c: 'Migraciones, relaciones de muchos-a-muchos',
    },
  },
  fr: {
    '0': {
      a: 'Informations générales',
      b: 'Introduction aux applications Web',
    },
    '1': {
      a: 'Premiers pas avec React',
      b: 'JavaScript',
      c: "État des composants, gestionnaires d'événements",
      d: "Plongez dans le débogage d'applications React",
    },
    '2': {
      a: 'Rendu de collections, modules',
      b: 'Formulaires',
      c: 'Obtenir des données du serveur',
      d: 'Modification des données côté serveur',
      e: 'Styliser vos applications React',
    },
    '3': {
      a: 'Node.js et Express',
      b: 'Déployer votre application sur Internet',
      c: 'Stocker des données sur MongoDB',
      d: 'Validation et ESLint',
    },
    '4': {
      a: "Structure de l'application backend, introduction aux tests",
      b: 'Tester le backend',
      c: 'Gestion des utilisateurs',
      d: "Jeton d'authentification",
    },
    '5': {
      a: 'Connexion front-end',
      b: 'props.children et proptypes',
      c: 'Tester les applications React',
      d: 'Tests de bout en bout',
    },
    '6': {
      a: 'Architecture de flux et Redux',
      b: 'Quelques reducers',
      c: "Communiquer avec le backend dans l'application Redux",
      d: 'React Query, useReducer et le contexte',
    },
    '7': {
      a: 'React-router',
      b: 'Hooks personnalisés',
      c: 'Allez-plus loin avec le style',
      d: 'Webpack',
      e: 'Apprivoisez les composants classe',
      f: 'Exercices: étendre la bloglist',
    },
    '8': {
      a: 'Serveur GraphQL',
      b: 'React et GraphQL',
      c: 'Base de données et gestion des utilisateurs',
      d: 'Connexion et mise à jour du cache',
      e: 'Fragments et subscriptions',
    },
    '9': {
      a: 'Contexte et introduction',
      b: 'Premiers pas avec TypeScript',
      c: "De l'application express à TypeScript",
      d: 'Écrire des applications React avec TypeScript',
    },
    '10': {
      a: 'Introduction à React Native',
      b: 'Les bases de React Native',
      c: 'Communiquer avec le serveur',
      d: 'Tester et étendre notre application',
    },
    '11': {
      a: 'Introduction au CI/CD',
      b: 'Premiers pas avec GitHub Actions',
      c: 'Déploiement',
      d: 'Rester dans le vert',
      e: 'Allez plus loin',
    },
    '12': {
      a: 'Introduction aux Conteneurs',
      b: 'Construire et configurer des environnements',
      c: "Bases de l'Orchestration",
    },
    '13': {
      a: 'Bases de données relationnelles avec Sequelize',
      b: 'Joindre tables et requêtes',
      c: 'Migrations, relations plusieurs-à-plusieurs',
    },
  },
  ptbr: {
    '0': {
      a: 'Informações gerais',
      b: 'Fundamentos de aplicações web',
    },
    '1': {
      a: 'Introdução à biblioteca React',
      b: 'JavaScript',
      c: 'Estado de componente e gerenciadores de eventos',
      d: 'Um estado mais complexo e depuração de aplicações React',
    },
    '2': {
      a: 'Renderização de uma coleção e módulos',
      b: 'Formulários',
      c: 'Obtendo dados do servidor',
      d: 'Alterando dados no servidor',
      e: 'Adicionando estilos à aplicação React',
    },
    '3': {
      a: 'Node.js e Express',
      b: 'Implantação da aplicação na internet',
      c: 'Salvando dados no MongoDB',
      d: 'Validação e ESLint',
    },
    '4': {
      a: 'Estrutura de uma aplicação back-end, introdução a testes',
      b: 'Testando o back-end',
      c: 'Administração de usuários',
      d: 'Autenticação por token',
    },
    '5': {
      a: 'Login no front-end',
      b: 'props.children e proptypes',
      c: 'Testando aplicações React',
      d: 'Testes end-to-end',
    },
    '6': {
      a: 'Arquitetura Flux e Redux',
      b: 'Muitos Reducers',
      c: 'Comunicação com o servidor em uma aplicação Redux',
      d: 'connect',
    },
    '7': {
      a: 'React-router',
      b: 'Hooks personalizados',
      c: 'Mais sobre estilos',
      d: 'Webpack',
      e: 'Componentes de classe, Miscelâneos',
      f: 'Exercícios: estendendo o bloglist',
    },
    '8': {
      a: 'Servidor GraphQL',
      b: 'React e GraphQL',
      c: 'Banco de dados e administração de usuários',
      d: 'Login e atualização do cache',
      e: 'Fragmentos e assinaturas',
    },
    '9': {
      a: 'Background e introdução',
      b: 'Primeiros passos com TypeScript',
      c: 'Tipando a aplicação Express',
      d: 'React com tipos',
    },
    '10': {
      a: 'Introdução à biblioteca React Native',
      b: 'Conceitos básicos de React Native',
      c: 'Comunicação com o servidor',
      d: 'Testando e estendendo nossa aplicação',
    },
    '11': {
      a: 'Introdução a CI/CD',
      b: 'Começando com GitHub Actions',
      c: 'Deployment',
      d: 'Mantendo tudo "limpo"',
      e: 'Expandindo ainda mais',
    },
    '12': {
      a: 'Introdução a Containers',
      b: 'Construindo e configurando ambientes',
      c: 'Conceitos básicos de Orquestração',
    },
    '13': {
      a: 'Usando bancos de dados relacionais com Sequelize',
      b: 'Tabelas de junção e consultas',
      c: 'Migrações e Relacionamentos de Muitos para Muitos',
    },
  },
};
